<template>
  <div
    style="background-color:var(--color-beige); color:var(--color-braun);"
  >
    <div class="bg bg-repeat-x h-10 lg:h-16" />
    <div class="max-w-5xl mx-auto px-5 lg:px-10 py-10 lg:py-32 text-center tracking-widest leading-8">
      <div class="uppercase">
        Kaffeegenuss mit
      </div>
      <div class="text-5xl md:text-8xl font-bold mb-10 font-['Boska']">
        Tradition.
      </div>
      <div class="uppercase mb-20">
        Unser Kaffeehaus am Mozartplatz 2a hat eine lange Tradition - und steckt voller Geschichten, Begegnungen und schöner Erinnerungen. Schon seit 1979 wurden in diesem Haus Torten gebacken, Pralinen gefertigt und unzählige süße Kunstwerke kreiert. Als langjähriger Mitarbeiter des ehemaligen Café Dallmann ist es mir eine besondere Ehre, diese Tradition nun mit meiner Familie weiterzutragen. Und das Haus mit viel Herz und Leidenschaft neu zu beleben.
      </div>
      <div class="mb-20">
        <b>Unser Tipp:</b> Wer einen Tagesausflug rund um den Wolfgangsee plant, startet am besten direkt bei uns in St. Gilgen mit einem kleinen, aber feinen Frühstück mit frischem Gebäck aus unserer eigenen Bäckerei!
      </div>
      <div class="mb-20">
        Sie sind auf der Suche nach einem süßen Souvenir oder einem Geschenk?
        In unserem Genuss-Shop finden Sie hochwertige Pralinen und Schokoladen der Wenschitz Schokoladen-Manufaktur!
      </div>
      <div>
        Wir freuen uns, Sie bald bei uns begrüßen zu dürfen!
      </div>
      <div class="text-4xl font-['Boska']">
        Vladimira Mekysova, Kevin Mezei
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodBye'
}
</script>

<style scoped lang="scss">
.bg {
  background-image: url('~@/assets/Grafiken/Genuss-Cafe-Muster.svg');
}
</style>
